// custom typefaces.
// Bitter.
import "@fontsource/bitter/400.css"; // Weight 400.
import "@fontsource/bitter/700.css"; // Weight 700.

// Merriweather Sans.
import "@fontsource/merriweather-sans/400.css"; // Weight 400.
import "@fontsource/merriweather-sans/700.css"; // Weight 700.

// Global styles.
import "./src/styles/global.scss"

// Code highlighting.
require("prismjs/themes/prism-okaidia.css")